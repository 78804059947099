import React from "react";
import { connect } from "react-redux";
import DonateView from "../components/DonateView";
import withTranslation from "../hocs/withTranslation";
import { resetDonateGift } from "../store/user/actions";
import { getStyles } from "../store/selectors";

const DonatePage = (props) => <DonateView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    app: { keyboardOpen },
  } = state;

  return { user, keyboardOpen, appStyles: getStyles(state, props) };
};

const mapDisaptchToProps = (dispatch, props) => {
  return { resetDonateGift: () => dispatch(resetDonateGift()) };
};

export default connect(
  mapStateToProps,
  mapDisaptchToProps,
)(withTranslation(DonatePage));
